.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
  }
  
  .modal-content img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f00;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
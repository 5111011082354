.project-details {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .project-details h2 {
    text-align: center;
    font-size: 2em;
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  .project-details p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .project-details a {
    color: #3498db;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
  }
  
  .project-details a:hover {
    text-decoration: underline;
  }
  
  .gallery {
    margin-top: 30px;
  }
  
  .gallery h3 {
    font-size: 1.5em;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .gallery-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
  }
  
  .gallery-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .gallery-thumbnail:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Modal styles */
  .image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease;
    z-index: 1000;
  }
  
  .image-modal.open {
    visibility: visible;
    opacity: 1;
  }
  
  .image-modal img {
    max-width: 90%;
    max-height: 80%;
    border-radius: 10px;
  }
  
  .image-modal .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 1.2em;
  }
  
  .image-modal .close-button:hover {
    background: #ccc;
  }
  
  .back-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  /* Embedded video styles */
  .video-gallery {
    margin-top: 30px;
  }
  
  .video-gallery h3 {
    font-size: 1.5em;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .video-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  iframe {
    width: 560px;
    height: 315px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  iframe:hover {
    transform: scale(1.03);
    transition: transform 0.3s ease;
  }
  
  @media (max-width: 600px) {
    .project-details h2 {
      font-size: 1.5em;
    }
  
    .project-details p {
      font-size: 1em;
    }
  
    .gallery-images {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  
    .video-container {
      flex-direction: column;
      align-items: center;
    }
  
    iframe {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
  
/* src/components/ProjectCard.css */
.project-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem;
    width: 250px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .project-image {
    width: 100%;           /* Ensure the image scales to the container width */
    max-width: 200px;       /* Limit the maximum width of the image */
    height: 150px;          /* Fixed height for uniformity */
    object-fit: cover;      /* Crop the image to fit within dimensions */
    border-radius: 8px;
    margin-bottom: 0.5rem;
  }
  
  .project-details h3 {
    margin: 0.5rem 0;
    font-size: 1.2rem;
  }
  
  .project-details p {
    font-size: 1rem;
    color: #555;
  }
  
  .project-details a {
    color: #61dafb;
    text-decoration: none;
    margin-top: 0.5rem;
    display: inline-block;
  }
  